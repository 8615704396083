import React, { FC } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { ITagMap } from 'src/interfaces/template';
import PageHeader from 'src/components/organisms/common/PageHeader';
import TagLink from 'src/components/molecules/tag/TagLink';

interface PropsType {
  title: string;
  tagMap: ITagMap;
}

const TagList: FC<PropsType> = ({ title, tagMap }) => {
  return (
    <>
      <PageHeader title={title} />
      <TagBox>
        {Object.values(tagMap)
          .sort((a, b) => b.count - a.count)
          .map(tag => (
            <TagLink tag={tag} />
          ))}
      </TagBox>
    </>
  );
};

export default TagList;

const TagBox = styled.div`
  ${tw`mx-2 my-4 flex flex-wrap`}
`;
