import React, { FC, memo } from 'react';

import { ITagMap, ITemplateProps } from 'src/interfaces/template';
import TagsTemplate from 'src/components/templates/tag/TagsTemplate';

interface PropsType
  extends ITemplateProps<{
    tagMap: ITagMap;
  }> {}

const TagsPageTemplate: FC<PropsType> = memo(({ pageContext }) => {
  return <TagsTemplate tagMap={pageContext.tagMap} />;
});

export default TagsPageTemplate;
