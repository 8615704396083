import React, { FC } from 'react';

import SEO from 'src/components/organisms/common/SEO';
import TagList from 'src/components/organisms/tag/TagList';
import { ITagMap } from 'src/interfaces/template';
import Layout from 'src/components/templates/layout/Layout';

interface PropsType {
  tagMap: ITagMap;
}

const TagsTemplate: FC<PropsType> = ({ tagMap }) => {
  const title = 'Tag Archive';
  return (
    <Layout>
      <SEO title={title} />
      <TagList title={title} tagMap={tagMap} />
    </Layout>
  );
};

export default TagsTemplate;
