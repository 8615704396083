import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

interface PropsType {
  title: string;
  children?: ReactNode;
}

const PageHeader: FC<PropsType> = ({ title, children }) => {
  return (
    <Head>
      <Title>{title}</Title>
      {children}
    </Head>
  );
};

export default PageHeader;

const Head = styled.div`
  ${tw`pb-2 border-b border-gray-300 dark:border-gray-700`}
`;

const Title = styled.h1`
  ${tw`font-extrabold my-4`}
`;
