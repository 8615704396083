import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import tw from 'twin.macro';
import { ITag } from 'src/interfaces/template';

interface PropsType {
  tag: ITag;
}

const TagLink: FC<PropsType> = ({ tag }) => {
  return (
    <Container>
      <Link to={`${tag.slug}`}>
        {tag.name}({tag.count})
      </Link>
    </Container>
  );
};

export default TagLink;

const Container = styled.div`
  ${tw`mx-3 mb-2 font-bold text-gray-800 dark:text-gray-200 hover:text-black hover:dark:text-white`}

  a {
    ${tw`text-lg`}
  }
`;
